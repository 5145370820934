import React, { Component } from "react";
// import Child1 from "./components/child1";
// import Child2 from "./components/child2";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Layout3 from "./pages/Layout3";


import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Login from "./login";
import Booksdashboard from "./books/booksdashboard";
import Exam11 from "./books/exam11";
import Examnew from "./books/examnew";
import Year from "./books/year";
import Department from "./books/department";
import Books from "./books/books";
import Path from "./books/path";
import If1 from "./pages/if1";
import "./App.css";
class App extends Component {

    callback = (count) => {
        this.setState({ count: count })
        
    }
    callback2 = (items) => {
        
        localStorage.setItem('qanswers',JSON.stringify(items));
        this.state.qanswers = localStorage.getItem('qanswers');
        alert(this.state.qanswers);
    }
    constructor(props) {
        super(props);
        
        this.state = {
            count: 0,
            qanswers:[]
          };
          //this.st=this.state.count;
      }
      componentDidMount() {
        //alert(this.st)
      }
      
render() {

    let w=window.location.pathname;
    //alert(w)
    if (w!="/exam11" || w!="/department")
    {
    }
    else{
    const tokenString = localStorage.getItem('token');
const userToken = JSON.parse(tokenString);
let token=userToken?.success;
if(token==undefined || token.length<10) {
return <Login />

}
}



return (
<BrowserRouter basename='/react/habib'>
<Routes>
<Route path="/" element={<Layout3 />}>
<Route index element={<Exam11 />} />
<Route path="exam11" element={<Exam11 />} />
<Route path="department" element={<Department />} />
<Route path="year" element={<Year />} />
<Route path="books" element={<Books />} />
<Route path="path" element={<Path />} />
<Route path="login" element={<Login />} />

</Route>
<Route path="/" element={<Layout />}>
<Route path="blogs" element={<Blogs />} />
<Route path="contact" element={<Contact />} />
<Route path="If1" element={<If1 />} />
<Route path="booksdashboard" element={<Booksdashboard />} />
<Route path="examnew" element={<Examnew />} />
<Route path="*" element={<NoPage />} />
</Route>
</Routes>
</BrowserRouter>
);
}
}
export default App;
