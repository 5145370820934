import React,{ useState, useEffect } from "react";
import $ from 'jquery';
import user from "./user.jpg"

function Items() {

var [items, setData] = useState([]);
var [lineitems, setData2] = useState([]);

var U="http://localhost:8000/GetController";

function convertDate(inputFormat) {
function pad(s) { return (s < 10) ? '0' + s : s; }
var d = new Date(inputFormat)
return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}




let [globalvariables, setGlobalVariables] = useState({
st: [],
index: 0,
U:"http://localhost:8000/GetController"
});
useEffect(() => {
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('bookname');
const year = urlParams.get('year');
const exam = urlParams.get('exam');
const department = urlParams.get('department');
//console.log(myParam)
$("#bookname").val(myParam);
//deptchange();
display(myParam,year,exam,department);
}, [])


function display(p,c,a,b){
$.get(`${U}/GetPath?bookname=${p}&year=${c}&exam=${a}&department=${b}`,{},function(data){ //exam=কামিল&&year=প্রথম বর্ষ&&department=আল হাদীস
data=JSON.parse(data)
globalvariables.st=data.records;
setData(data);
setData2(globalvariables.st);
});
}

return(
<div class="container-fluid">
<table class="table bordered">
<caption>Total Rows: <span id="tot">{items.total}</span></caption>
<thead>
<th>বই</th>
</thead>
<tbody id="tb">
{items.map(function(d, index) {
return <tr >
<td>{d.path}</td>
</tr> 
})}
</tbody>
</table>
</div>
)
}
export default Items;

// <iframe frameborder="0" scrolling="no" style="border:0px" src="https://books.google.com.kh/books?id=e5MkzETNcsgC&lpg=PP1&dq=typography&pg=PA11&output=embed" width="500" height=500>
// </iframe>