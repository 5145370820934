import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import $ from 'jquery';
import { createBrowserHistory } from "history";
async function loginUser() {
let user=$("#username").val();
let pass=$("#password").val();
return fetch(`http://localhost/React/book/JWT/checklogin.php?username=${user}&password=${pass}`)
.then(data => data.json())
}
export default function Login({ setToken }) {
const [username, setUserName] = useState();
const [password, setPassword] = useState();
const handleSubmit = async e => {
e.preventDefault();
const token = await loginUser();
localStorage.setItem('token', JSON.stringify(token));
const history = createBrowserHistory();
//alert(history.location.pathname)
if(history.location.pathname=="/login")
window.location="http://localhost:4200/layout";
else
window.location.reload();
}

return(

    
<div className="login-wrapper" style={{textAlign:'center',  backgroundImage: `url("assetc/img/login.jpg")` , borderRadius:"30px", margin:"auto"}}>

   <br></br>
<h2 style={{color:"white"}}>Please Log-In</h2>

<form onSubmit={handleSubmit}>
<label>
<p style={{fontWeight:"bold", fontSize:"20PX",color:"white"}}> Name</p>
<input type="text" id="username" style={{width:"150PX", height:"40PX", borderRadius:'10px'}} onChange={e => setUserName(e.target.value)} />
</label>
<label>
<p style={{fontWeight:"bold", fontSize:"20PX",color:"white"}}>Password</p>
<input type="password" id="password" style={{width:"150PX", height:"40PX", borderRadius:'10px'}} onChange={e => setPassword(e.target.value)} />
</label>
<div>
<button className='bg-info' type="submit" >Submit</button>
</div>
</form>
</div>
)
}

Login.propTypes = {
setToken: PropTypes.func.isRequired
};
