import React,{ useState, useEffect } from "react";
import $ from 'jquery';

function Items() {

    
var [items, setData] = useState([]);
var [lineitems, setData2] = useState([]);

var U="http://localhost:8000/bdashboardController";

function convertDate(inputFormat) {
function pad(s) { return (s < 10) ? '0' + s : s; }
var d = new Date(inputFormat)
return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}




let [globalvariables, setGlobalVariables] = useState({
st: [],
index: 0,
U:"http://localhost:8000/bdashboardController"
});
useEffect(() => {
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('slno');
//console.log(myParam)
$("#slno").val(myParam);
deptchange();
display();
}, [])

function deptchange() {
    fetch('http://localhost:8000/bdashboardController/Getclass?slno=' + $("#slno").val())
    .then(response => response.json())
    .then(data => {
    console.log(data)
    setData(data);
    //$("#questionid").val(data[0].questionid)
    $("#exam").val(data[0].exam)
    $("#department").val( (data[0].department)) 
    $("#year").val(data[0].year)
    $("#bookname").val(data[0].bookname)
    $("#path").val(data[0].path)
    //////alert(data[0].linedue)
    display();
    })
    .catch(error => {
    // Handle the error
    });
    }

    function examchange() {
        fetch('http://localhost:8000/bdashboardController/Getexam?exam=' + $("#exam").val())
        .then(response => response.json())
        .then(data => {
        console.log(data)
        setData(data);
        //$("#questionid").val(data[0].questionid)
        $("#department").val( (data[0].department)) 
        //////alert(data[0].linedue)
        display();
        })
        .catch(error => {
        // Handle the error
        });
        }


function display(){
$.get(`${U}/GetClasses`,{},function(data){
data=JSON.parse(data)
globalvariables.st=data.records;
setData(data);
setData2(globalvariables.st);
});
}

function add(){
let a=$("#slno").val();
let c=$("#exam").val();
let d=$("#department").val();
let e=$("#year").val();
let f=$("#bookname").val();
let g=$("#path").val();
let  url=`${U}/InsertClass?slno=${a}&exam=${c}&department=${d}&year=${e}&bookname=${f}&path=${g}`;
console.log(url);
$.get(url,{},function(data){
//alert(data);
data=JSON.parse(data)
$("#action").html(data.total+" records Inserted");
work();
});


}
function update(){
let a=$("#slno").val();
let c=$("#exam").val();
let d=$("#department").val();
let e=$("#year").val();
let f=$("#bookname").val();
let g=$("#path").val();
let url=`${U}/Updateclass?slno=${a}&exam=${c}&department=${d}&year=${e}&bookname=${f}&path=${g}`;
$.get(url,{},function(data){
work();
});

}
function remove(i){
let a=i;
let url=`${U}/DeleteClass?slno=${a}`;
$.get(url,{},function(data){
work();
});
}

function show(a,c,d,e,f,g,i){
$("#slno").val(a);
$("#exam").val(c);
$("#department").val(d);
$("#year").val(e);
$("#bookname").val(f);
$("#path").val(g);
$("#imgprev").css("display",'block');
$("#imgprev").attr("src","upload/"+g);
globalvariables.index=i;
}


function work(){
display();
$("#slno").val("");
$("#exam").val("");
$("#department").val("");
$("#year").val("");
$("#bookname").val("");
$("#path").val("");
}


function upload(){
    var fd = new FormData();
    
    var files = $('#file')[0].files;
    
    // Check file selected or not
    if(files.length > 0 ){
    
    fd.append('file',files[0]);
    
    $.ajax({
    url:globalvariables.U+'/upload.php',
    type:'post',
    data:fd,
    dataType: 'json',
    contentType: false,
    processData: false,
    success:function(response){
    if(response.status == 1){
//    alert(JSON.stringify(response.name))
    var extension = response.extension;
    var path = response.path;
    $("#path").val(response.name);
    $('.prevel').hide();
    if(extension == 'pdf' || extension == 'docx'){
    $("#fileprev").attr("href",path);
    $("#fileprev").show();
    }else{
    $("#imgprev").attr("src",path);
    $("#imgprev").show();
    }
    
    }else{
    //alert('File not uploaded');
    }
    }
    });
    }else{
    //alert("Please select a file.");
    }
    
    }
    
    function onFileChange(event){
    let status = false
    const file = event.target.files[0];
    //alert(file.name)
    status = event.target.files.length>0?true:false
    if(status==true){
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
    document.getElementById("imgprev").src=reader.result;
    
    }
    }
    }
    



return(
<div class="container-fluid">
<div class="row sticky-top px-4 py-0 ">

<div className="col-sm-1">
<div class="form-group">
<label for="slno">slno</label>
<input type="text" id="slno" class="form-control" onChange={() =>deptchange()}/>
</div>
</div>

<div className="col-sm-2">

<div class="form-group">
<label for="exam">exam</label>
<input type="text" id="exam" class="form-control"/>
</div>
</div>

{/* <div className="col-sm-1">
<div className="form-group">
<select name="cmbid" id="cmbid" onChange={profleChange}><option value="">Select</option>
{items && items.map((item, index) => (
<option value={item.exam}>{item.exam}</option>
))}
</select>
</div>
</div> */}


<div className="col-sm-2">
<div class="form-group">
<label for="department">department</label>
<input type="text" id="department" class="form-control"/>
</div>
</div>

<div className="col-sm-2">
<div class="form-group">
<label for="year">year</label>
<input type="text" id="year" class="form-control"/>
</div>
</div>


<div className="col-sm-3">
<div class="form-group">
<label for="bookname">bookname</label>
<input type="text" id="bookname" class="form-control"/>
</div>
</div>

<div className="col-sm-2">
<div class="form-group">
<label for="path">path</label>
<input type="text" id="path" class="form-control"/>
</div>
</div>

<div class="form-group">
<input type="file" id="file" name="file" onChange={(event)=>onFileChange(event)} />
<input type="hidden" id="path" />
<input type="button" class="button" value="Upload" id="but_upload" onClick={()=>upload()}/>
<div style={{marginTop: "40px"}} >


<img src="" class="prevel img-fluid" id="imgprev" width="300" style={{display: "none"}} />


<a href="#" target="_blank" class="prevel" id="fileprev" style={{display: "none"}}>View File</a>
</div>
</div>

<div class="form-group">
<br/>
<div class="btn-group" role="group" aria-label="Basic mixed styles example">
<button type="button" class="btn btn-info" onClick={()=>add()}>Add</button>
<button type="button" class="btn btn-success" onClick={()=>update()}>Update</button>
</div>
</div>
</div>


<table class="table">
<caption>Total Rows: <span id="tot">{items.total}</span></caption>
<thead>
<th>slno</th>
<th>exam</th>
<th>department</th>
<th>year</th>
<th>bookname</th>
<th>path</th>
<th>Action</th>

</thead>
<tbody id="tb">
{items.map(function(d, index) {
return <tr onClick={()=>show(d.slno,d.exam,d.department,d.year,d.bookname,d.path,index)} >
<td>{d.slno}</td>
<td>{d.exam}</td>
<td>{d.department}</td>
<td>{d.year}</td>
<td>{d.bookname}</td>
<td><img src={`${globalvariables.U}/upload/${d.path}`} class="img-fluid"/></td>
<td><button type="button" class="btn btn-danger" onClick={()=>remove(d.slno)} >Remove</button></td>
</tr>
})}
</tbody>
</table>
</div>
)
}
export default Items;
