import React,{ useState, useEffect } from "react";
import $ from 'jquery';

function Items() {

var [items, setData] = useState([]);
var [lineitems, setData2] = useState([]);

var U="http://localhost:8000/GetController";

function convertDate(inputFormat) {
function pad(s) { return (s < 10) ? '0' + s : s; }
var d = new Date(inputFormat)
return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}




let [globalvariables, setGlobalVariables] = useState({
st: [],
index: 0,
U:"http://localhost:8000/GetController"
});
useEffect(() => {
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('department');
const exam = urlParams.get('exam');
//console.log(myParam)
$("#department").val(myParam);
//deptchange();
display(myParam,exam);
}, [])


function display(b,c){
  //  alert(`${U}/GetYear?department=${b}&exam=${c}`);
$.get(`${U}/GetYear?department=${b}&exam=${c}`,{},function(data){ //exam=ফাযিল&&year=প্রথম বর্ষ
data=JSON.parse(data)
globalvariables.st=data.records;
setData(data);
setData2(globalvariables.st);
});
}

return(
<div class="container-fluid">
<table class="table">
<thead>
</thead>
<tbody id="tb">
<div class="row" style={{textAlign:"center"}}>
{items.map(function(d, index) {
    let c=`books?year=${d.year}&exam=${d.exam}&department=${d.department}`
   // alert(c);
return <div class="col-sm-3" >
   
<div class="card-body" style={{background:"white", height:"100px", borderRadius:"20px"}}>
<a href={c} class="btn btn-primary btn-lg btn-block" style={{marginTop:"15px"}} >{d.year}</a>
</div>
</div>

{/* <tr >
<td><a href={c}><button type="button" className="btn btn-info" >{d.year}</button></a>
</td>
</tr> */}

})}
</div>
</tbody>
</table>
</div>
)
}
export default Items;
