import React,{ useState, useEffect } from "react";
import $ from 'jquery';
import user from "./user.jpg"

function Items() {

var [items, setData] = useState([]);
var [lineitems, setData2] = useState([]);

var U="http://localhost:8000/GetController";

function convertDate(inputFormat) {
function pad(s) { return (s < 10) ? '0' + s : s; }
var d = new Date(inputFormat)
return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}




let [globalvariables, setGlobalVariables] = useState({
st: [],
index: 0,
U:"http://localhost:8000/GetController"
});
useEffect(() => {
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('year');
const exam = urlParams.get('exam');
const department = urlParams.get('department');
//console.log(myParam)
$("#year").val(myParam);
//deptchange();
display(myParam,exam,department);
}, [])


function display(c,d,f){
   // alert(`${U}/GetBooks?year=${c}&exam=${d}&department=${f}`)
$.get(`${U}/GetBooks?year=${c}&exam=${d}&department=${f}`,{},function(data){ //exam=কামিল&&year=প্রথম বর্ষ&&department=আল হাদীস
data=JSON.parse(data)
globalvariables.st=data.records;
setData(data);
setData2(globalvariables.st);
});
}

return(
<>


<div class="container-fluid" >
<table class="table bordered" style={{background:"white", borderRadius:"20px"}}>
<thead>
<th style={{textAlign:"center"}}>বই সমূহ</th>
</thead>
<tbody id="tb">
{items.map(function(d, index) {
    let path="http://localhost:8000/book/upload/"+d.path;
return <tr >
<td>{d.bookname}</td>
<td><a href={path} class="btn btn-info" >Read</a></td> 
</tr> 

})}
</tbody>
</table>
</div>
</>


)
}
export default Items;

// <iframe frameborder="0" scrolling="no" style="border:0px" src="https://books.google.com.kh/books?id=e5MkzETNcsgC&lpg=PP1&dq=typography&pg=PA11&output=embed" width="500" height=500>
// </iframe>