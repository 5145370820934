import { Outlet, Link } from "react-router-dom";
import user from "./user.jpg"
const Layout = () => {
    function logOut(){
    localStorage.clear();
   // alert("gone");
    window.location="/exam11";
    }    
return (
<>
<div class="position bg-white d-flex p-0" >
<div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
<div class="spinner-border text-primary" style={{width: "3rem", height: "3rem"}} role="status">
<span class="sr-only">Loading...</span>

</div>
</div>

<div>
<div class="sidebar pe-4 pb-3 ">
<nav class="navbar bg-light navbar-light">

<div class="nav-item dropdown ms-5">
<a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
<img class="rounded-circle" src={user} alt="" style={{width: "120px", height: "120px"}}/>
<h6 class="mb-2" style={{fontSize:"30px"}}>My Profile</h6>
<span style={{fontSize:"20px"}}>Admin</span>
</a>
<div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
<a href="#" class="dropdown-item">My Profile</a>
<a href="#" class="dropdown-item">Settings</a>
<a href="#" onClick={() => logOut()} class="dropdown-item">Log Out</a>
</div>
</div>
<div class="navbar-nav w-100">
<a href="index.html" class="nav-item nav-link active"><i class="fa fa-tachometer-alt me-2"></i>Dashboard</a>
<div class="nav-item dropdown">
<a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i class="far fa-file-alt me-2"></i>Books</a>
<div class="dropdown-menu bg-transparent border-0">
<a href="booksdashboard" class="nav-item nav-link"><button type="button" class="btn btn-danger">Books Dashboard</button></a>
<a href="exam11" class="nav-item nav-link"><button type="button" class="btn btn-secondary">Books</button></a>
<a href="examreport22" class="nav-item nav-link"><button type="button" class="btn btn-primary">Exam</button></a>
</div>
</div>
</div>
</nav>
</div>
</div>

<div class="content">
<nav class="navbar navbar-expand navbar-light sticky-top px-4 py-0" style={{backgroundColor:"#e3f2fd",width:"100%"}}>

<a href="#" className="sidebar-toggler flex-shrink-0">
    <i className="fa fa-bars">
      
    </i>
</a>

<div class="ms-5">
</div>

<a href="booksdashboard" class="nav-item nav-link">Books Dashboard</a>
<a href="exam11" class="nav-item nav-link">বই সমূহ</a>




{/* <div class="nav-item dropdown ms-5">
<a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
<i class="fa fa-envelope me-lg-2"></i>
<span class="d-none d-lg-inline-flex">books Tool</span>
</a>
<div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
<a href="exambooks" class="nav-item nav-link"> books Dashboard</a>
<a href="getbookbyexam" class="nav-item nav-link"> exam</a>
<a href="getbookbyexamandyear" class="nav-item nav-link"> Year</a>
<a href="getbookbyexamandyearanddepartment" class="nav-item nav-link"> department</a>
<a href="getbookbyexamandyearanddepartmentbooks" class="nav-item nav-link"> books</a>

<a href="#" class="dropdown-item text-center"></a>
</div>
</div> */}


{/* <div class="btn-group">
  <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
  Books
  </button>
  <ul class="dropdown-menu">
    <li><a href="booksdashboard" class="nav-item nav-link"><i class="fa fa-th me-2" ></i >Books dashboard</a>
</li>
<li><a href="exam11" class="nav-item nav-link"> Books</a>
</li>
<li><a href="department" class="nav-item nav-link"> department</a>
</li>
<li><a href="year" class="nav-item nav-link"> Year</a>
</li>
<li><a href="books" class="nav-item nav-link"> books</a>
</li>
  </ul>
</div> */}

<form class="float-sm-right ms-auto ">
<input class="form-control border-0 " type="search" placeholder="Search"/>
</form>

</nav>



<br/>



<Outlet />



</div>




<a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
</div>
</>
)
};

export default Layout;
