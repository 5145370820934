import React,{ useState, useEffect } from "react";
import $ from 'jquery';
// import user from "./user.jpg"

function Items() {

var [items, setData] = useState([]);
var [lineitems, setData2] = useState([]);

var U="http://localhost/React/hacker-stories/src/exam";
let [globalvariables, setGlobalVariables] = useState({
st: [],
index: 0,
U:"http://localhost/React/hacker-stories/src/exam"
});
useEffect(() => {
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('exam');
//console.log(myParam)
$("#exam").val(myParam);
//deptchange();
display();
}, [])


function display(){
$.get(`${U}/getquestion.php?`,{},function(data){
data=JSON.parse(data)
globalvariables.st=data.records;
setData(data);
setData2(globalvariables.st);
});
}

return(
    <>
<div class="container-fluid">
<table class="table">
<thead>
</thead>
<tbody id="tb">

{lineitems.map(function(d, index) {

return <td>{d.topics}</td>
})}

</tbody>
</table>
</div>

</>
)
}

export default Items;
