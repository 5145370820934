import React,{ useState, useEffect } from "react";
import $ from 'jquery';
// import user from "./user.jpg"

function Items() {

var [items, setData] = useState([]);
var [lineitems, setData2] = useState([]);

var U="http://localhost:8000/GetController";
let [globalvariables, setGlobalVariables] = useState({
st: [],
index: 0,
U:"http://localhost:8000/GetController"
});
useEffect(() => {
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('exam');
//console.log(myParam)
$("#exam").val(myParam);
//deptchange();
display();
}, [])


function display(){
$.get(`${U}/GetClass1?`,{},function(data){
data=JSON.parse(data)
globalvariables.st=data.records;
setData(data);
setData2(globalvariables.st);
});
}

return(
    <>
<div class="container-fluid" >
<table class="table">
<thead>
</thead>
<tbody id="tb">
<div class="row" style={{textAlign:"center"}}>
{items.map(function(d, index) {
    let a=`department?exam=${d.exam}`;

return <div class="col-sm-3" >
   
      <div class="card-body" style={{background:"#F0FFFF", height:"100px", borderRadius:"20px"}}>
      <a href={a} class="btn btn-primary btn-lg btn-block" style={{marginTop:"15px"}} >{d.exam}</a>
      </div>
  </div>


{/* <td><a href={a}>{d.exam}</a>
</td>
<img class="img-thumbnail" src={user} alt="" style={{width: "100px", height: "100px"}}/> */}
})}
</div>
</tbody>
</table>
</div>

</>
)
}

export default Items;
