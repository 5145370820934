const Contact = () => {
    return (
    <div>
    <p>I am a paragraph.</p>
    <p>I am a paragraph too.</p>
    </div>
    );
    
    };
    export default Contact;
    